.columnId {
    text-align: left;
}

.columnStatus {
    text-align: left;
}

.columnName {
    text-align: left;
}

.columnDescription {
    text-align: left;
}